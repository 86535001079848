import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { LoadshopEnvironment } from '../app/shared/models/loadshop-environment';
export const environment: LoadshopEnvironment = {
  production: false,
  debug: true,
  apiUrl: 'https://loadshop-api-q1.kbxl.com/loadshop.app.api/v1',
  identityServerUrl: 'https://identity-q1.kbxl.com',
  siteUrl: 'https://loadshop-q1.kbxl.com',
  baseUrl: '/',
  googleKey: 'AIzaSyC3nr_rMsuo9Ku8Q47DLIZBP_Sv9AJmCDc',
  gaTrackingId: 'UA-131254949-1',
  tops2GoApiUrl: 'https://mobile-q1.kbxl.com/t2g',
  recaptchaSiteKey: '6Lds5dsUAAAAAC8Xpi-o1FOSU0ggh5EjcrpGzuVG',
  conversationApiUrl: 'https://api.qa2.kbxl.cloud/exp/api/core/hubmessageapi',
  conversationWebSocketUrl: 'https://api.qa2.kbxl.cloud/exp/api/core/hubwebsocketapi',
};

export const extModules = [
  StoreDevtoolsModule.instrument({
    name: 'LoadShop DevTools',
    maxAge: 50,
  }),
];
